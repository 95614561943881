:root {
  --primaryColor: #4295cf;
  --primaryBoldColor: #8cbad1;
  --primaryBlackColor: #15649c;
  --secondaryColor: #ffffff;
  --opaqueBackground: #00000080;
  --mainCanvasBackground: #141414;
  --primaryTextSize: 15px;
  --primaryTextSizeS: 10px;
  --letterSpacing: 5px;
  --radius: 4px;
  --contentTransitionDuration: 600ms;
  --fastTransitionDuration: 300ms;
  --contentTransitionBezier: cubic-bezier(0.83, 0.39, 0.21, 0.96);
}

.header-wallet {
  border: 1px solid #87b9d0;
  border-radius: 12px;
  padding: 8px 16px;
  box-shadow: 1px 1px 1px 0px #83b7cd59;
  justify-content: center;
}

.button {
  position: relative;
  border: none;
  background-color: transparent;
  padding: 15px 30px;
  /* letter-spacing: var(--letterSpacing); */
  font: 700 15px Roboto, sans-serif;
  border-radius: var(--radius);
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
}

.button-balance {
  color: var(--primaryColor);
  position: relative;
  background-color: transparent;
  padding: 0px 10px;
  /* letter-spacing: var(--letterSpacing); */
  font: 700 15px Roboto, sans-serif;
  font-size: 15px;
  border-radius: var(--radius);
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
}
.button-balance:focus {
  /* outline: 1px dotted; */
  outline: none;
}

.header-balance {
  color: var(--primaryColor);
  font: 700 var(--primaryTextSize) Roboto, sans-serif;
}

.button--third {
  display: flex;
  align-items: center;
  border: 2px solid var(--primaryColor);
  /* color: var(--secondaryColor); */
  color: black;
  /* background-color: #14141499; */
}
.button--third:hover {
  background: #4295cfd9;
  transition: background 300ms ease;
}
.button--third.button-main {
  background: #4295cf42;
}
.button--third.disabled {
  background: #efefef;
  cursor: no-drop;
}

.button--small {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--primaryColor);
  /* color: var(--secondaryColor); */
  color: black;
  /* background-color: #14141499; */
  padding: 8px 12px;
  letter-spacing: 2px;
  font-size: 12px;
}
.button--small:hover {
  background: #4295cfd9;
  transition: background 300ms ease;
}

.home {
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}

.metamask-wrapper {
  /* width: 100%; */
  max-width: 480px;
}
.metamask-controls {
  padding-bottom: 24px;
  width: 100%;
}
.metamask-buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 0;
}
.metamask-controls-button {
  margin-bottom: 30px;
}
.connect-metamask {
  padding: 2px 16px;
}
.connect-metamask-logo {
  height: 42px;
  width: 42px;
}
.orders-history-button {
  margin-top: 50px;
  margin-bottom: 30px;
}

/*  */

.horizontal-slider {
  width: 100%;
  height: 8px;
  margin-bottom: 20px;
}

.purchase-track {
  top: 0;
  bottom: 0;
  border-radius: 999px;
  /* margin-top: 8px; */
  height: 8px;
}

.purchase-track.purchase-track-0 {
  background: #4095ce;
}

.purchase-track-1 {
  background: #90bfd6;
  margin-left: 10px;
}

.purchase-thumb.purchase-thumb-0 {
  top: -9px;
  height: 27px;
  width: 27px;
  line-height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #4095ce;
  /* border: 1px solid #07619f; */
  box-shadow: 1px 1px 2px 1px #0000002e;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
}

.purchase-thumb:hover {
  /* background-color: #4095ce; */
  /* background-color: #ed7800; */
  /* color: #fff; */
}

.purchase-mark {
  /* margin-top: 8px; */
  width: 1px;
  height: 100%;
  background-color: #00000061;
  margin-left: 12px;
}

.purchase-mark:nth-of-type(15) {
  height: 200%;
}

#yeti-offer {
  background-color: #ff562f07;
}

.yeti-offer-text {
  margin-left: 12px;
}

del {
  margin-right: 4px;
}

.best-value-sub {
  margin-top: -12px;
}

.best-value {
  position: absolute;
  top: -85px;
  right: 15px;
  transform: rotate3d(0, 0, 1, 350deg);
  text-transform: uppercase;
  color: #90bfd688;
}
