#app,body,html {
  height: 100%;
  margin: 0
}

.bg-grey-darkest-30 {
  background: rgba(56,60,67,.3)
}

.border-red-40 {
  border-color: rgba(246,79,100,.4)
}

.border-grey-darkest-40 {
  border-color: rgba(56,60,67,.4)
}

.bg-grey-darkest-20 {
  background-color: rgba(56,60,67,.2)
}

.bg-red-25 {
  background-color: rgba(246,79,100,.25)
}

.bg-orange-25 {
  background-color: rgba(255,126,35,.25)
}

.bg-blue-25 {
  background-color: rgba(70,135,255,.25)
}

.bg-red-15 {
  background-color: rgba(246,79,100,.15)
}

.bg-blue-15 {
  background-color: rgba(70,135,255,.15)
}

.bg-green-15 {
  background-color: rgba(39,190,86,.15)
}

.bg-orange-15 {
  background-color: rgba(255,126,35,.15)
}

.hover\:bg-grey-custom:hover {
  background-color: #20242c
}

.hover\:bg-grey-lightest:hover {
  background-color: #f8f8f8
}

.ml-6px {
  margin-left: 6px
}

.mr-6px {
  margin-right: 6px
}

.transition-color {
  transition-property: background-color,color,fill,stroke
}

@media (min-width: 475px) {
  .smd\:mt-0 {
      margin-top:0
  }

  .smd\:mt-6 {
      margin-top: 24px
  }

  .smd\:mt-12 {
      margin-top: 48px
  }

  .smd\:-mb-6 {
      margin-bottom: -24px
  }

  .smd\:-mx-6 {
      margin-left: -24px;
      margin-right: -24px
  }

  .smd\:pb-6 {
      padding-bottom: 24px
  }

  .smd\:pt-8 {
      padding-top: 32px
  }

  .smd\:px-6 {
      padding-left: 24px;
      padding-right: 24px
  }

  .smd\:shadow-1 {
      box-shadow: 0 0 0 1px rgba(56,60,67,.05),0 1px 3px 0 rgba(56,60,67,.15)
  }

  .smd\:rounded {
      border-radius: 3px
  }

  .smd\:bg-grey-lightest {
      --bg-opacity: 1;
      background-color: #f8f8f8;
      background-color: rgba(248,248,248,var(--bg-opacity))
  }
}

.Theme--dark .bg-white {
  --bg-opacity: 1;
  background-color: #151922;
  background-color: rgba(21,25,34,var(--bg-opacity))
}

.Theme--dark .bg-grey-lightest {
  background-color: #0e1016
}

@media (min-width: 475px) {
  .Theme--dark .smd\:bg-grey-lightest {
      background-color:#0e1016
  }
}

.Theme--dark .text-black,.Theme--dark .text-grey-darkest {
  --text-opacity: 1;
  color: #d0d1d3;
  color: rgba(208,209,211,var(--text-opacity))
}

.Theme--dark .text-blue {
  --text-opacity: 1;
  color: #77a7ff;
  color: rgba(119,167,255,var(--text-opacity))
}

.Theme--dark .text-green {
  --text-opacity: 1;
  color: #66d589;
  color: rgba(102,213,137,var(--text-opacity))
}

.Theme--dark .text-orange {
  --text-opacity: 1;
  color: #ffaa6e;
  color: rgba(255,170,110,var(--text-opacity))
}

.Theme--dark .text-red {
  --text-opacity: 1;
  color: #f86e80;
  color: rgba(248,110,128,var(--text-opacity))
}

.Theme--dark .hover\:bg-grey-lightest:hover {
  background-color: rgba(56,60,67,.2)
}

.Theme--dark .border-grey-lighter {
  border-color: rgba(56,60,67,.4)
}

.Keyboard--active :focus {
  border-radius: 3px;
  box-shadow: 0 0 0 3px #305eb2;
  outline: none;
  z-index: 20
}

.Theme-switcher:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.Language-select option {
  --text-opacity: 1;
  color: #151922;
  color: rgba(21,25,34,var(--text-opacity))
}

.Card {
  max-width: 418px;
  min-height: auto
}

@media (min-width: 475px) {
  .Card {
      min-height:600px
  }
}

.SpinnerOverlay--dark {
  background-color: rgba(14,16,22,.9)
}

.SpinnerOverlay--light {
  background-color: hsla(0,0%,100%,.9)
}

.SpinnerOverlay {
  -webkit-animation: fadeIn .25s;
  animation: fadeIn .25s
}

@-webkit-keyframes fadeIn {
  0% {
      opacity: 0
  }

  to {
      opacity: 1
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0
  }

  to {
      opacity: 1
  }
}
